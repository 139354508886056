import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div id="footer-brasil" className="page-footer">
        <div id="wrapper-footer-brasil">
          <Link
            className="logo-acesso-footer"
            to="//www.acessoainformacao.gov.br/"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
